import { createSlice } from "@reduxjs/toolkit";

const LDTSlice = createSlice({
  name: "LDT",
  initialState: {
    files: [],
  },
  reducers: {
    addFiles: (state, action) => {
      action.payload.forEach((element) => {
        console.log(element.name);
      });
    },
    removeFile: (state, action) => {
      state.files = state.files.filter((file) => file.name !== action.payload);
    },
  },
});

export const { addFiles, removeFile } = LDTSlice.actions;

export default LDTSlice.reducer;
