import React from "react";
import SimpleLoading from "../components/SimpleLoading";
import { Col, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { setColorTemperature } from "../redux/slices/filterSlice";

const ColorTemperatureView = () => {
  const dispatch = useDispatch();
  const { color_temperature } = useSelector((state) => state.filter);
  const { color_temperature_list, loading_color_temperature_list } =
    useSelector((state) => state.filterList);

  const invalidcolorTemperature = (temp) => {
    return temp.indexOf("-") !== -1 || temp === "0K";
  };

  return (
    <React.Fragment>
      {(loading_color_temperature_list || (color_temperature_list == null) ) ? (
        <SimpleLoading />
      ) : (
        <Col sm="12" style={{ height: "64vh", overflowY: "scroll" }}>
          {Object.entries(color_temperature_list).map(
            ([temperature, value]) => {
              return invalidcolorTemperature(temperature) ? null : (
                <Col
                  xs="11"
                  sm="11"
                  key={temperature}
                  style={{
                    fontSize: "0.3rem",
                    margin: "0.3rem",
                    padding: "1em",
                    backgroundColor: "rgb(208, 203, 203)",
                    color: "black",
                    borderRadius: "1em",
                  }}
                >
                  <Form.Check
                    type="radio"
                    name="colorTemperature"
                    label={temperature + " (" + value + ")"}
                    style={{ fontSize: "1.2rem" }}
                    onChange={() => {
                      dispatch(setColorTemperature(temperature));
                    }}
                    checked={temperature === color_temperature}
                  />
                </Col>
              );
            }
          )}
        </Col>
      )}
    </React.Fragment>
  );
};

export default ColorTemperatureView;
