import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../utility/API";
import { setConnectionError } from "./appState";

export const updateColorTemperatureList = createAsyncThunk(
  "filterList/updateColorTemperatureList",
  async (args, { dispatch, getState }) => {
    try {
      const filter = getState().filter;
      return await API.fetch(
        "color_temperature_count",
        "POST",
        JSON.stringify({
          mounting_type: filter?.mounting_type ?? null,
          norm: filter?.norm ?? null,
        })
      );
    } catch (error) {

    
      dispatch(setConnectionError(true));
    } finally {
    }
  }
);
const sort = (Listpayload) => {
  if (!Listpayload || typeof Listpayload !== "object") {
    console.error("Invalid Listpayload:", Listpayload);
    return null;
  }

  // Get the keys and sort them
  const sortedKeys = Object.keys(Listpayload).sort((a, b) => {
    // Sort numerically if keys are numbers, otherwise sort as strings
    const numA = parseInt(a, 10);
    const numB = parseInt(b, 10);

    if (!isNaN(numA) && !isNaN(numB)) {
      return numA - numB; // Numeric sorting
    }

    return a.localeCompare(b); // String sorting
  });

  // Create a new object with sorted keys
  const sortedObj = {};
  sortedKeys.forEach((key) => {
    sortedObj[key] = Listpayload[key];
  });

  return sortedObj;
};
export const updateVendorList = createAsyncThunk(
  "filterList/updateVendorList",
 async (_, { dispatch, getState }) => {
    try {
      const filter = getState().filter;
      return await API.fetch(
        "vendor_count",
        "POST",
        JSON.stringify({
          mounting_type: filter?.mounting_type ?? null,
          norm: filter?.norm ?? null,
          color_temperature: filter?.color_temperature ?? null,
        })
      );
    } catch (error) {
      
      dispatch(setConnectionError(true));
    } finally {
    }
  }
);

const filterListSlice = createSlice({
  name: "filterList",
  initialState: {
    color_temperature_list: null,
    loading_color_temperature_list: true,
    vendor_list: null,
    loading_vendor_list: true,
  },

  extraReducers: (builder) => {
    builder
      .addCase(updateColorTemperatureList.pending, (state) => {
        state.loading_color_temperature_list = true;
      })
      .addCase(updateColorTemperatureList.fulfilled, (state, action) => {
        state.loading_color_temperature_list = false;

        state.color_temperature_list = sort(action.payload);
      })
      .addCase(updateColorTemperatureList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateVendorList.pending, (state) => {
        state.loading_vendor_list = true;
      })
      .addCase(updateVendorList.fulfilled, (state, action) => {
        state.loading_vendor_list = false;
        state.vendor_list = sort(action.payload);
      })
      .addCase(updateVendorList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setVendorList, setColorTemperatureList } =
  filterListSlice.actions;

export default filterListSlice.reducer;
