import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { TiDelete } from "react-icons/ti";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  getJob,
  historyDeleteFileById,
  getHistory,
  addToCachedHistory,
  loadCachedHistory,
  saveCachedHistory,
  removeFromCachedHistory,
  setJob,
} from "../redux/slices/postFilterSlice";
export default function HistoryJobs() {
  const [key, setKey] = useState("home");
  const dispatch = useDispatch();
  const { history, cachedHistory, ableToAddCache } = useSelector(
    (state) => state.postFilter
  );

  useEffect(() => {
    console.log(cachedHistory);
    dispatch(saveCachedHistory());
    dispatch(getHistory());
  }, [cachedHistory]);

  return (
    <Tabs
      activeKey={key}
      onSelect={(k) => setKey(k)}
      variant="pills"
      className="m-3 text-primary"
      fill
    >
      <Tab eventKey="cached" title="Cached">
        <Row style={{ placeContent: "space-evenly" }}>
          {cachedHistory
            ? cachedHistory.map((item, index) => (
                <Col
                  key={"Col" + index + Math.random()}
                  xs={5}
                  className="bg-dark bg-gradient m-1 p-1 rounded"
                >
                  <Row
                    key={index}
                    className="m-3"
                    style={{
                      display: "flex",
                      aspectRatio: "12/4",
                      placeContent: "space-evenly",
                    }}
                  >
                    <Col
                      style={{ cursor: "alias" }}
                      onClick={() => {
                        try {
                          dispatch(setJob(item.result));
                        }catch{}
                      }}
                      xs={12}
                      key={index + item.name}
                      className="text-light"
                    >
                      {item.id}
                    </Col>

                    <Col className="text-info">
                      Cached on: {item.creation_date}
                    </Col>
                    <Col xs={11}>
                      <hr></hr>
                      <Button variant="outline-primary" className="text-light"
                        onClick={() => {
                          try {
                            dispatch(setJob(item.result));
                          }catch{}
                        }}
                      >
                        Load
                      </Button>
                      <Button
                        variant="outline-dark"
                        className="text-light"
                        style={{ float: "right" }}
                        onClick={() => {
                          dispatch(removeFromCachedHistory({ id: item.id }));
                        }}
                      >
                        remove
                      </Button>
                    </Col>
                  </Row>
                </Col>
              ))
            : null}
        </Row>
      </Tab>
      <Tab eventKey="home" title="from Server">
        <Row style={{ placeContent: "space-evenly" }}>
          {history?.files
            ? history.files.map((item, index) => (
                <Col
                  key={"Col" + index + Math.random()}
                  xs={5}
                  className="bg-dark bg-gradient m-1 p-1 rounded"
                >
                  <Row
                    key={index}
                    className="m-3"
                    style={{
                      display: "flex",
                      aspectRatio: "12/4",
                      placeContent: "space-evenly",
                    }}
                  >
                    <Col xs={12}>
                      <div
                        onClick={() => {
                          dispatch(historyDeleteFileById(item.name));
                        }}
                        style={{ width: "30%", float: "right" }}
                      >
                        <TiDelete style={{ fill: "white", float: "right" }} />
                      </div>
                    </Col>
                    <Col
                      style={{ cursor: "alias" }}
                      onClick={() => {
                        try {
                          dispatch(getJob(history.files[index].name));
                        } finally {
                          dispatch(getHistory());
                        }
                      }}
                      xs={12}
                      key={index + item.name}
                      className="text-light"
                    >
                      {item.name}
                    </Col>

                    <Col className="text-info">
                      {" "}
                      Created on: {item.creation_date}
                    </Col>
                    <hr></hr>
                    <Col xs={11}>
                      <Button
                        className="text-light"
                        variant="outline-primary"
                        onClick={() => {
                          try {
                            dispatch(getJob(history.files[index].name));
                          } finally {
                            dispatch(getHistory());
                          }
                        }}
                      >
                        Load
                      </Button>
                      <Button
                        disabled={!ableToAddCache}
                        className="text-light"
                        variant="outline-dark"
                        style={{ cursor: "alias", float: "right" }}
                        onClick={() => {
                          dispatch(addToCachedHistory(item.name));
                        }}
                      >
                        set to cache
                      </Button>
                    </Col>
                  </Row>
                </Col>
              ))
            : null}
        </Row>
      </Tab>
    </Tabs>
  );
}
