import React from "react";
import { Card, Table } from "react-bootstrap";
import { useSelector } from "react-redux";

const Failed = () => {
  const job = useSelector((state) => state.postFilter.job);

  if (!job) {
    return;
  }

  return (
    <React.Fragment>
      <Card data-bs-theme="dark" className="m-3">
        <Card.Body>
          <blockquote className="blockquote mt-0">
            <p>
              The following {job.failed.length} luminaires did not fulfill the
              prerequisites...
            </p>
            <footer className="blockquote-footer">
              The reason why they failed can be seen below.
            </footer>
          </blockquote>
        </Card.Body>
      </Card>
      <Table
        className="w-100 ratio-16x9 mt-3"
        variant="dark"
        striped
        bordered
        hover
      >
        <thead>
          <tr>
            <th>Luminaire</th>
            <th>Reason</th>
          </tr>
        </thead>
        <tbody>
          {job.failed
            ? job.failed.map((item, index) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>
                    {Array.isArray(item.reason) ? (
                      <ul>
                        {item.reason.map((reason, idx) => (
                          <li key={idx}>{reason}</li>
                        ))}
                      </ul>
                    ) : (
                      item.reason
                    )}
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </Table>
    </React.Fragment>
  );
};

export default Failed;
