import React from "react";
import Plot from "react-plotly.js"; // Make sure to install this package: npm install react-plotly.js plotly.js
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";

const Convergence = () => {
  const job = useSelector((state) => state.postFilter.job);
  const data = structuredClone(job.convergence);

  const darkTheme = {
    width: window.innerWidth * 0.9,
    height: window.innerHeight * 0.6,
    font: {
      family: "Arial, sans-serif",
      size: 18,
      color: "#ffffff",
    },

    paper_bgcolor: "rgba(0,0,0,0)",
    plot_bgcolor: "#2c2c2c",
    xaxis: {
      gridcolor: "#444",
      zerolinecolor: "#888",
      color: "#ffffff",
    },

    yaxis: {
      gridcolor: "#444",
      zerolinecolor: "#888",
      color: "#ffffff",
    },

    title: {
      font: {
        size: 18,
        color: "#ffffff",
      },
    },
    legend: {
      font: {
        size: 18,
        color: "#ffffff",
      },
    },
  };

  if (!job) {
    return;
  }

  return (
    <React.Fragment>
      <Card data-bs-theme="dark" className="m-3">
        <Card.Body>
          <blockquote className="blockquote mb-0">
            <p>
              Convergence Plots show the Basics of minimizing the Functions{" "}
              {job.passed.length + job.failed.length} Luminaires have been
              optimized.
            </p>
            <footer className="blockquote-footer">
              Their results are shown below the Tag :
              <cite title="Source Title"> {job.id}</cite>
            </footer>
          </blockquote>
        </Card.Body>
      </Card>

      <Plot
        data={data.data}
        layout={{ ...data.layout, ...darkTheme }}
        config={data.config}
      />
    </React.Fragment>
  );
};

export default Convergence;
