import { useDispatch } from "react-redux";
import { setConnectionError } from "../redux/slices/appState";
import { Exception } from "sass";

export class API {
  static url = "https://automizer.relux.com/api/";

  static async fetch(endpoint, method, body) {
    try {
      const options = {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
      };

      if (method === "POST") {
        options.body = body;
      }

      const response = await fetch(this.url + endpoint, options);

      if (response.ok) {
        console.log("Successfully called: ", endpoint);
        return await response.json();
      } else {
        console.log("Failed to call: ", endpoint);
      }
    } catch (error) {
      
      throw Exception(error)
     
    }
  }

  static async downloadfile(file) {
    try {
      /*const response = await fetch(
        "download",
        "POST",
        JSON.stringify({
          file: file, // Send the file path in the JSON body
        })*
      );*/
      const response = await fetch("https://automizer.relux.com/api/download", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          file: file, // Send the file path in the JSON body
        }),
      });
      if (response.ok) {
        // Create a Blob from the response data
        const blob = await response.blob();

        // Create a link element
        const link = document.createElement("a");
        const downloadUrl = window.URL.createObjectURL(blob);
        link.href = downloadUrl;

        // Set the filename for the download
        const fileName = file.split("/").pop(); // Extract the file name from the path
        link.download = fileName;

        // Append to the body (necessary for Firefox)
        document.body.appendChild(link);

        // Trigger the download by simulating click
        link.click();

        // Clean up
        window.URL.revokeObjectURL(downloadUrl);
        document.body.removeChild(link);
      } else {
        console.log(response);
        alert("Failed to call download ...");
      }
    } catch (error) {
      console.error("Error during POST request in download func:", error);
    }
  }
}
