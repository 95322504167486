import React from "react";
import { Col, Form } from "react-bootstrap";
import SimpleLoading from "../components/SimpleLoading";
import { useSelector, useDispatch } from "react-redux";
import { addVendor, removeVendor } from "../redux/slices/filterSlice";

const Vendorview = () => {
  const { vendor_list, loading_vendor_list } = useSelector(
    (state) => state.filterList
  );
  const { vendors } = useSelector((state) => state.filter);
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      {(loading_vendor_list ||  vendor_list == null) ? (
        <SimpleLoading />
      ) : (
        <Col sm="12" style={{ height: "64vh", overflowY: "scroll" }}>
          {Object.entries(vendor_list).map(([vendor, value]) => (
            <Col
              xs="11"
              sm="11"
              key={vendor}
              style={{
                fontSize: "0.3rem",
                margin: "0.3rem",
                padding: "1em",
                backgroundColor: "rgb(208, 203, 203)",
                color: "black",
                borderRadius: "1em",
              }}
            >
              <Form.Check
                type="checkbox"
                label={vendor + " (" + value + ")"}
                style={{ fontSize: "1.2rem" }}
                onChange={() =>
                  vendors.includes(vendor)
                    ? dispatch(removeVendor(vendor))
                    : dispatch(addVendor(vendor))
                }
                checked={vendors.includes(vendor)}
              />
            </Col>
          ))}
        </Col>
      )}
    </React.Fragment>
  );
};

export default Vendorview;
