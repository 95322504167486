import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";

import LuminaireList from "../../components/LuminaireList";
import Filter from "./Filter";

const Passed = () => {
  const job = useSelector((state) => state.postFilter.job);
  const luminaires = useSelector((state) => state.postFilter.luminaires);

  if (!job) {
    return;
  }

  return (
    <React.Fragment>
      <Card data-bs-theme="dark" className="m-3">
        <Card.Body>
          <blockquote className="blockquote mb-0">
            <p>
              {" "}
              With the given prefilter, the following{" "}
              {job?.passed?.length ?? "0"} luminaires fulfills the required
              prerequisites...
            </p>
            <p>
              Applying the filter, there are{" "}
              {luminaires?.length ?? job?.passed?.length ?? "0"} luminiares to
              choose from...
            </p>
            <footer className="blockquote-footer">
              Their results are shown below...
            </footer>
          </blockquote>
        </Card.Body>
      </Card>
      <Row
        style={{
          alignContent: "space-between",
        }}
      >
        <Col
          sm={6}
          className="mt-3"
          style={{ height: "55vh", overflowY: "scroll" }}
        >
          <LuminaireList />
        </Col>
        <Col sm={6} className="mt-3">
          <Filter />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Passed;
