import React from 'react'

import { Row, Col } from 'react-bootstrap';
import { MdNearbyError } from "react-icons/md";
import { VscError } from "react-icons/vsc";

import { useDispatch } from 'react-redux'
import { setConnectionError } from '../redux/slices/appState'

export default function ConnectionErrorpage() {
const dispatch = useDispatch();
  return (
    <Row id="mainbg" style={{width:'100wv',height:'100vh',justifyContent:'center',alignContent:'center'}}>
        <Col xs={6}>
        <Row style={{height:'100%',width:'100%',marginTop:'-7.7rem'}}>
            <Col xs={12}>
                
                <MdNearbyError size={'12rem'} style={{float:'right'}}/>
            </Col>
            <Col xs={10} style={{marginTop:'-7.7rem',position:'relative'}}>
            <div className='display-5 text-light' style={{whiteSpace:'nowrap'}}>An connection error appeared.</div>
            <h1>
            <hr></hr>
            </h1>
            </Col>
            <Col xs={12} className='text-light'>
            connection to Server: <VscError style={{fill:'red'}}/>
            </Col>
        </Row>
        </Col>
        </Row>
  )
}
