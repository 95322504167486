import React from "react";
import { Button, Row, Col } from "react-bootstrap";
import NormInput from "../components/NormInput";
import RecadInput from "../components/RecadInput";
import LDTInput from "../components/LDTInput";
import LDTList from "../components/LDTList";
import ws from "../utility/websocket";

import { resetFilter } from "../redux/slices/filterSlice";
import { setTab } from "../redux/slices/appState";
import { useSelector, useDispatch } from "react-redux";
import Optimize from "../components/Optimize";

export default function CustomUpload() {
  const filter = useSelector((state) => state.filter);
  const { luminaires } = useSelector((state) => state.filter);
  console.log(luminaires);
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <Row className="w-100 h-100" style={{ placeContent: "space-evenly" }}>
        <Col sm={6}>
          <Row
            className="m-3 w-100"
            style={{
              margin: "0",
              padding: "0",
              placeContent: "space-evenly",
            }}
          >
            <Col sm={12} className="m-3">
              <Optimize />
            </Col>
            <Col sm={12} className="m-3">
              <RecadInput />
            </Col>
            <Col sm={12} className="m-3">
              <LDTInput />
            </Col>
            <Col sm={12} className="m-3">
              <NormInput />
            </Col>
            <Col sm={12} className="m-3">
              <Button
                className="w-100"
                onClick={async (event) => {
                  ws.send(JSON.stringify({ type: "start", data: filter }));
                  dispatch(setTab("jobs"));
                  dispatch(resetFilter());
                }}
                style={{ float: "right" }}
              >
                Start Automizer
              </Button>
            </Col>
          </Row>
        </Col>

        {luminaires && luminaires.length > 0 && (
          <Col sm={6}>
            <LDTList />
          </Col>
        )}
      </Row>
    </React.Fragment>
  );
}
