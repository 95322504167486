import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const validated = (state) => {
  state.validated =
    state.norm !== null &&
    state.mounting_type !== null &&
    state.color_temperature !== null &&
    state.recad_file !== null &&
    state.optimisation_function !== null &&
    state.vendors.length;
};

//loadDefault(defaultRecad);
const initialState = {
  validated: false,
  norm: null,
  recad_file: null,
  luminaires: [],
  mountingType: null,
  optimisation_function: null,
  colorTemperature: null,
  vendors: [],
};

const filterSlice = createSlice({
  name: "filter",
  initialState: initialState,
  reducers: {
    resetVendorColortemprature: (state, action) => {
      state.vendors = [];
      state.colorTemperature = null;
      state.norm = null;
    },
    addLuminaires: (state, action) => {
      const newFiles = action.payload;
      const uniqueFiles = newFiles.filter(
        (newFile) =>
          !state.luminaires.some((file) => file.name === newFile.name)
      );
      if (uniqueFiles.length < 1) return;
      state.luminaires = [...state.luminaires, ...uniqueFiles];
    },
    removeLuminaire: (state, action) => {
      state.luminaires = state.luminaires.filter(
        (luminaire) => luminaire.name !== action.payload
      );
      return;
    },

    setLdt: (state, action) => {
      state.ldt = action.payload;
    },
    setValidated: (state, action) => {
      state.validated = action.payload;
    },
    setNorm: (state, action) => {
      state.norm = action.payload;
      validated(state);
    },
    setRecadFile: (state, action) => {
      state.recad_file = action.payload;
      validated(state);
    },
    setMountingType: (state, action) => {
      state.mounting_type = action.payload;
      validated(state);
    },
    setOptimizationFunction: (state, action) => {
      state.optimisation_function = action.payload;
      validated(state);
    },
    setColorTemperature: (state, action) => {
      state.color_temperature = action.payload;
      validated(state);
    },
    addVendor: (state, action) => {
      state.vendors.push(action.payload);
      validated(state);
    },
    removeVendor: (state, action) => {
      state.vendors = state.vendors.filter(
        (vendor) => vendor !== action.payload
      );
      validated(state);
    },
    resetLuminaires: (state) => {
      state.luminaires = initialState.luminaires;
    },
    resetFilter: (state) => {
      state.vendors = [];
      state.colorTemperature = [];
      // this resets the state to its initial state
    },
  },
});

export const {
  setValidated,
  setNorm,
  setRecadFile,
  setMountingType,
  setOptimizationFunction,
  setColorTemperature,
  addVendor,
  removeVendor,
  resetFilter,
  addLuminaires,
  removeLuminaire,
  resetLuminaires,
  resetVendorColortemprature,
} = filterSlice.actions;

export default filterSlice.reducer;
