import { createSlice } from "@reduxjs/toolkit";





const appState = createSlice({
  name: "appState",
  initialState: {
    tab: "prefilter",
    connectionError:false,
  },
  reducers: {
    setTab: (state, action) => {
      state.tab = action.payload;
    },
    setConnectionError: (state, action) => {
      state.connectionError = action.payload;
    },
  },

});

export const { setTab,setConnectionError } = appState.actions;

export default appState.reducer;
