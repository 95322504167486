import { configureStore } from "@reduxjs/toolkit";
import filterReducer from "./slices/filterSlice";
import appStateReducer from "./slices/appState";
import LDTReducer from "./slices/LDTSlice";
import filterListReducer from "./slices/filterListSlice";
import postFilterReducer from "./slices/postFilterSlice";

const store = configureStore({
  reducer: {
    filter: filterReducer,
    appState: appStateReducer,
    LDT: LDTReducer,
    filterList: filterListReducer,
    postFilter: postFilterReducer,
  },
},window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default store;
