import React, { useEffect } from "react";
import { setRecadFile } from "../redux/slices/filterSlice";
import { useDispatch } from "react-redux";
import defaultFile from "../square_new.recad";
const RecadInput = () => {
  const dispatch = useDispatch();

  const fetchFileAsBase64 = async () => {
    const response = await fetch(defaultFile);
    const blob = await response.blob();

    // Convert Blob to Base64
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result.split(",")[1]; // Extract Base64
      dispatch(
        setRecadFile({
          name: "defaultFile.png", // Replace with your file name
          base64: base64String,
        })
      );
    };
    reader.readAsDataURL(blob);
  };
  useEffect(() => {
    fetchFileAsBase64();
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    // Read the file as Base64 string
    reader.onload = (event) => {
      const base64String = event.target.result.split(",")[1]; // Extract Base64 part
      dispatch(
        setRecadFile({
          name: file.name,
          base64: base64String,
        })
      );
    };

    if (file) {
      reader.readAsDataURL(file); // This triggers the `onload` event
    }
  };

  return (
    <React.Fragment>
      <div data-bs-theme="dark" className="input-group custom-file-button">
        <label
          className="input-group-text"
          style={{
            width: "30%",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          ReCAD File :
        </label>
        <input
          type="file"
          className="form-control"
          id="inputGroupFile"
          onChange={handleFileChange}
        />
      </div>
    </React.Fragment>
  );
};

export default RecadInput;
