import React, { useState } from "react";
import { Form, Offcanvas, Button, Row, Col } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { setNorm } from "../redux/slices/filterSlice";
import pluralize from "pluralize";
import structuredData from "../roomtypes.json";
import { retry } from "@reduxjs/toolkit/query";
export default function NormInput() {
  const filter = useSelector((state) => state.filter);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSearchChange = (e) => setSearchTerm(e.target.value);

  const adjustedStructuredData = structuredData.map((item) => {
    // Get reference_number and type_of_task values
    const referenceNumber =
      item["reference_number"]?.["value"]?.toLowerCase() || "";
    const typeOfTask = item["type_of_task"]?.["value"]?.toLowerCase() || "";

    // Create a search tag by combining reference_number and type_of_task
    const searchTag = `${referenceNumber} ${typeOfTask}`.trim(); // trim to remove any excess spaces

    // Return a new object with the search_tag added
    return {
      ...item, // Spread the existing properties
      search_tag: searchTag, // Add the new search_tag attribute
    };
  });
  let filteredData = Array.isArray(adjustedStructuredData)
    ? adjustedStructuredData.filter((item) => {
        const searchTag = item["search_tag"]?.toLowerCase() || "";

        const singularTerm = pluralize.singular(searchTerm.toLowerCase());
        const pluralTerm = pluralize.plural(searchTerm.toLowerCase());

        // Return match for type_of_task or search term
        return (
          searchTag.includes(singularTerm) ||
          searchTag.includes(pluralTerm) ||
          searchTag.includes(searchTerm.toLowerCase())
        );
      })
    : [];
  const referenceNumbers = filteredData
    .map((item) => item["reference_number"]?.["value"]?.toLowerCase() || "")
    .filter(Boolean) // Filter out any undefined or null values
    .map((referenceNumber) => referenceNumber.split(".")[0]);

    const databyid = Array.isArray(adjustedStructuredData)
    ? adjustedStructuredData
        .filter((item) => {
            const referenceNumberValues = item["reference_number"]?.["value"]?.toLowerCase() || "";
            const searchTag = item["search_tag"]?.toLowerCase() || "";
            
            // Check if reference number starts with any of the referenceNumbers (prefix match)
            return referenceNumbers.some((refNum) =>
                referenceNumberValues.startsWith(refNum) // Match only the beginning of the reference number
            );
        })
        .map((item) => {
            // Add attribute indicating if search_tag matches the searchTerm
            const matchesSearchTag = item["search_tag"]?.toLowerCase().includes(searchTerm);
            
            return {
                ...item,
                matchesSearchTag: matchesSearchTag // Add the new matchesSearchTag attribute
            };
        })
    : [];
    console.log(databyid)
    


  // Ensure reference_number values exist
  const data = databyid.filter(
    (item) => item["reference_number"]?.["value"] !== undefined
  );

  return (
    <>
      <Button className="w-100" variant="primary" onClick={handleShow}>
        {filter.norm === null
          ? "Select a room profile"
          : `${filter.norm[0]} | ${filter.norm[1]}`}
      </Button>

      <Offcanvas
        id="searchcanvar"
        placement="bottom"
        show={show}
        onHide={handleClose}
        className="bg-dark"
        style={{ height: "100vh" }}
      >
        <Offcanvas.Header
          style={{
            background: "rgba(0,0,0,0)!Important",
            backgroundColor: "rgba(0,0,0,0)!Important",
            backdropFilter: "blur(20px)",
          }}
          closeButton
          closeVariant="white"
        >
          <Form.Control
            type="text"
            className="m-1"
            style={{ width: "45vw" }}
            placeholder="Search for Room Norm"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <FaSearch size={"1.5rem"} className="m-1 text-primary" />
        </Offcanvas.Header>
        <Offcanvas.Body id="searchfullfill">
          <Row
            style={{
              display: "flex",
              overflowX: "hidden",
              overflowY: "scroll",
              justifyContent: "space-evenly",
              gap: "1.3rem",
            }}
          >
            {data.map((item, index) =>
              item["reference_number"]?.["value"].includes(".") ? ( // Ensure the button is rendered only if "Ref. no." exists
                <Col
                  xs={3}
                  key={
                    item["reference_number"]["value"] +
                    item["type_of_task"]["value"] +
                    index
                  }
                >
                  <Button
                    
                    className=""
                    style={{ aspectRatio: "3/2" }}
                    variant={item["matchesSearchTag"]?("secondary"):("outline-secondary")}
                    onClick={() => {
                      setShow(false);
                      dispatch(
                        setNorm([
                          item["reference_number"]["value"],
                          item["type_of_task"]["value"],
                        ])
                      );
                    }}
                  >
                    <Row className="m-3 text-white">
                      {item["reference_number"]["value"] && (
                        <>
                          <strong
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignContent: "center",
                            }}
                          >
                            <div
                              style={{ marginRight: "0.3rem", float: "top" }}
                              className="text-primary"
                            >
                              {item["reference_number"]["value"]}
                            </div>
                            {item["type_of_task"]["value"]}
                          </strong>
                          <hr />
                        </>
                      )}

                      {item["em_required"]["value"] && (
                        <small>
                          <strong>Ēm:</strong> {item["em_required"]["value"]} lx
                        </small>
                      )}
                      <br />
                      {item["uo"]["value"] && (
                        <small>
                          <strong>Uo:</strong> {item["uo"]["value"]}
                        </small>
                      )}
                      <br />
                      {!(item["specific_requirements"]["value"] == "—") && (
                        <small>
                          <strong className="text-primary">
                            Specific Requirements:
                          </strong>{" "}
                          {item["specific_requirements"]["value"]}
                        </small>
                      )}
                    </Row>
                  </Button>
                </Col>
              ) : (
                <Col
                  xs={12}
                  style={{ padding: "3.7rem" }}
                  key={
                    item["reference_number"]["value"] +
                    item["type_of_task"]["value"] +
                    index
                  }
                >
                  <div className="w-100 h-100">
                    <hr className="h1 text-light"></hr>
                    <div
                      className="text-primary mt-3"
                      style={{
                        fontSize: "1.3rem",
                      }}
                    >
                      {item["reference_number"]["value"] + " | "}
                      {item["type_of_task"]["value"] + " "}{" "}
                    </div>
                  </div>
                </Col>
              )
            )}
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
