import "bootstrap/dist/css/bootstrap.min.css";
import "./custom.scss";
import "./App.css";

import PrefilterRouter from "./Router_prefilter";

function App() {
  return <PrefilterRouter />;
}

export default App;
