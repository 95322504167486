import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Dropdown, ButtonGroup } from "react-bootstrap";
import { IoSettingsOutline } from "react-icons/io5";
export default function Thememode() {
  // Function to check if normal mode is enabled
  const isNormalModeEnabled = () => {
    return document.body.classList.contains("normal-mode");
  };

  const [themeMode, setThemeMode] = useState(
    isNormalModeEnabled() ? "normal" : "dark"
  );

  const toggleThemeMode = () => {
    if (isNormalModeEnabled()) {
      document.body.classList.remove("normal-mode");
      document.body.classList.add("dark-mode"); // Assuming you have a dark mode class as well
      setThemeMode("dark");
    } else {
      document.body.classList.add("normal-mode");
      document.body.classList.remove("dark-mode"); // Remove dark mode class
      setThemeMode("normal");
    }
  };

  useEffect(() => {
    // Initialize theme on load
    if (isNormalModeEnabled()) {
      document.body.classList.add("normal-mode");
    } else {
      document.body.classList.add("dark-mode");
    }
  }, []);

  return (
    <div className="d-flex align-items-center">
      <Dropdown
        as={ButtonGroup}
        size="sm"
        className="rounded-1"
        data-bs-theme="dark"
        menuvariant="dark"
      >
        <Dropdown.Toggle
          className="border-0"
          split
          style={{ background: "rgba(0.0.0.0)" }}
        >
          <span
            style={{
              textAlign: "center",
              textJustify: "center",
            }}
          >
            <IoSettingsOutline style={{ stroke: "#e6007e" }} className="m-1" />{" "}
            Settings
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item eventKey="1">
            <Button
              variant={themeMode === "dark" ? "dark" : "light"}
              onClick={toggleThemeMode}
            >
              {themeMode === "dark"
                ? "Switch to Light Theme"
                : "Switch to Dark Theme"}
            </Button>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
