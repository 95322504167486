import store from "../redux/store";
import { updateProgress, getHistory } from "../redux/slices/postFilterSlice";
import { setConnectionError } from "../redux/slices/appState";

class WebSocketInterface {
  constructor(url, protocols = []) {
    this.url = url;
    this.protocols = protocols;
    this.reconnectInterval = 5000; // 20 seconds
    this.ws = null;
    this.connect();
  }

  connect() {
    this.ws = new WebSocket(this.url, this.protocols);

    this.ws.onopen = (event) => {
      console.log("Connected to WebSocket server");
      store.dispatch(setConnectionError(false));
      if (this.onopen){
         this.onopen(event);
        }
    };
 

    this.ws.onmessage = (event) => {
      try {
        console.log("Message from server:", event.data);
        const data = JSON.parse(event.data);
        if (data.type === "status_update") {
          store.dispatch(updateProgress(data.payload));
          if (data.payload.luminaire_count === data.payload.finished_count)
            store.dispatch(getHistory());
        }
      } catch (error) {
        console.error("Error parsing JSON data in message receiver:", error);
      }
    };

    this.ws.onerror = (error) => {
      console.error("WebSocket error:", error);
      store.dispatch(setConnectionError(true));
      if (this.onerror) this.onerror(error);
    };

    this.ws.onclose = (event) => {
      console.log("Disconnected from WebSocket server");
      store.dispatch(setConnectionError(true));
      if (this.onclose) this.onclose(event);
      this.reconnect();
    };
  }

  reconnect() {
    setTimeout(() => {
      console.log("Attempting to reconnect...");
      if (
        this.ws.readyState === WebSocket.CLOSED ||
        this.ws.readyState === WebSocket.CLOSING
      )
        this.connect();
    }, this.reconnectInterval);
  }

  send(data) {
    if (this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(data);
    } else {
      console.error("WebSocket is not open. Ready state:", this.ws.readyState);
    }
  }

  close() {
    clearTimeout(this.reconnectTimeout);
    this.ws.close();
  }
}

const ws = new WebSocketInterface("wss://automizer.relux.com/ws/");

export default ws;
