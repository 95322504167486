import React, { useEffect } from "react";
import { API } from "../utility/API";
import { Table } from "react-bootstrap";
import { MdOutlineFileDownload } from "react-icons/md";
import { useSelector } from "react-redux";

export default function LuminaireList() {
  const luminaires = useSelector((state) => state.postFilter.luminaires);

  useEffect(() => {
    console.log(luminaires);
  }, [luminaires]);

  return (
    <React.Fragment>
      {luminaires && luminaires.length > 0 ? (
        <Table
          striped
          bordered
          hover
          variant="dark"
          className="border-rounded"
          style={{ fontSize: "0.8em" }}
        >
          <thead className="border rounded">
            <tr>
              <th>Name</th>
              <th>Count</th>
              <th>Em</th>
              <th>Em Ceiling</th>
              <th>Em Wall</th>
              <th>Em Z</th>
              <th>UGRL</th>
            </tr>
          </thead>
          <tbody>
            {luminaires.map((item, index) => (
              <tr
                key={index}
                onClick={() => {
                  console.log(luminaires);
                  console.log(item);
                  API.downloadfile(item.result_file);
                }}
              >
                <td>
                  <MdOutlineFileDownload
                    style={{ fill: "#e6007e" }}
                  ></MdOutlineFileDownload>

                  {" " + item.name}
                </td>
                <td>{item.calculation_results.luminaire_count}</td>
                <td>{item.calculation_results.E_m}</td>
                <td>{item.calculation_results.E_m_ceiling}</td>
                <td>{item.calculation_results.E_m_wall}</td>
                <td>{item.calculation_results.E_m_z}</td>
                <td>{item.calculation_results.UGRL}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No data available to display</p>
      )}
    </React.Fragment>
  );
}
