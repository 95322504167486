import React from "react";
import { MdDeleteForever } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { removeLuminaire, resetLuminaires } from "../redux/slices/filterSlice";
import { GrPowerReset } from "react-icons/gr";

const LDTList = () => {
  const dispatch = useDispatch();

  const luminaires = useSelector((state) => state.filter.luminaires);
  return (
    <div className="w-100">
      {luminaires && luminaires.length > 0 && (
        <>
          <div className="w-100">
            <GrPowerReset
              className="text-primary"
              size={"1.3rem"}
              onClick={() => {
                dispatch(resetLuminaires());
              }}
              style={{ float: "right" }}
            />
          </div>
          <div
            className="rounded m-3 text-light scrollcustom"
            style={{
              height: "40vh",
              overflowY: "scroll",
              textAlign: "center",
            }}
          >
            <hr style={{ top: "0px", position: "sticky" }}></hr>
            {luminaires.map((file, index) => (
              <div
                key={index}
                onClick={() => dispatch(removeLuminaire(file.name))}
              >
                {file.name}
                <MdDeleteForever style={{ fill: "#e6007e" }}></MdDeleteForever>
              </div>
            ))}
            <hr style={{ bottom: "0px", position: "sticky" }}></hr>
          </div>
        </>
      )}
    </div>
  );
};

export default LDTList;
