import React, { useEffect } from "react";
import { Container, Row, Tabs, Tab, Col } from "react-bootstrap";
import Prefilter from "./Prefilter";
import ColorTemperatureView from "./ColorTemperatureView";
import Vendorview from "./Vendorview";
import CustomUpload from "./CustomUpload";
import { TbTemperatureSun } from "react-icons/tb";
import { CiShoppingTag } from "react-icons/ci";
import { useSelector, useDispatch } from "react-redux";
import Thememode from "../components/Thememode";
import { setTab } from "../redux/slices/appState";
import { setJob } from "../redux/slices/postFilterSlice";
import Jobs from "./Jobs";
import ConnectionErrorpage from "./ConnectionErrorpage";

export default function MainContainer() {
  const dispatch = useDispatch();
  const { tab,connectionError } = useSelector((state) => state.appState);

  useEffect(() => {
    if (tab !== "jobs") dispatch(setJob(null));
  }, [dispatch, tab]);

if(connectionError)return(<ConnectionErrorpage/>);


  return (
    <Container fluid>
      <Row
        id="mainbg"
        style={{
          height: "100vh",
          overflow: "hidden",
          justifyContent: "space-around",
        }}
      >
        <Col sm={12}>
          <Tabs
            className="mt-3 w-100 text-primary"
            activeKey={tab}
            onSelect={(e) => dispatch(setTab(e))}
            data-bs-theme="dark"
            style={{ display: "flex", justifyContent: "center" }}
            justify
          >
            <Tab
              id="contenttabmain"
              eventKey="prefilter"
              title="Prefilter"
              className="w-100 h-100"
            >
              <Row
                style={{
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  height: "7vh",
                }}
              >
                <Col>
                  <Thememode />
                </Col>
              </Row>

              <Row>
                <Col>
                  <Prefilter className="navback" />
                </Col>
                <Col sm={3}>
                  <Row>
                    <Col
                      className="m-3 text-light"
                      style={{
                        position: "sticky",
                        height: "1.7rem",
                        zIndex: "10",
                      }}
                    >
                      <TbTemperatureSun />
                      Select Color Temperature
                    </Col>
                    <ColorTemperatureView />
                  </Row>
                </Col>
                <Col sm={3}>
                  <Row style={{ overflow: "hidden" }}>
                    <Col
                      className="m-3 text-light"
                      style={{
                        position: "sticky",
                        height: "1.7rem",
                        zIndex: "10",
                        placeContent: "center",
                      }}
                    >
                      <CiShoppingTag /> Select Vendor
                    </Col>
                    <Vendorview />
                  </Row>
                </Col>
              </Row>
            </Tab>
            <Tab
              eventKey="custom"
              title="Custom Luminaire Upload"
              className="w-100 m-auto h-75"
            >
              <CustomUpload />
            </Tab>
            <Tab eventKey="jobs" title="Jobs & History">
              <Jobs />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
}
