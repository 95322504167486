import React from "react";
import Plot from "react-plotly.js"; // Make sure to install this package: npm install react-plotly.js plotly.js
import { useDispatch, useSelector } from "react-redux";
import {
  setLuminaires,
  updateFilter,
} from "../../redux/slices/postFilterSlice";

const Filter = () => {
  const job = useSelector((state) => state.postFilter.job);
  const filter = structuredClone(job.filter);
  const dispatch = useDispatch();

  const handleFilterChange = () => {
    var filtered = job.passed;
    // Handle filter being empty
    if (filter.data.length > 0) {
      for (const dimension of filter.data[0]["dimensions"]) {
        // There are no constraints, continue...
        if (!dimension.constraintrange) continue;

        // Filter luminaire list
        filtered = filtered.filter((luminaire) => {
          return insideConstraints(
            dimension.constraintrange,
            luminaire["calculation_results"][dimension.label]
          );
        });
      }
    }

    dispatch(setLuminaires(filtered));
    dispatch(updateFilter(filter));
  };

  /**
   *
   * @param {An array containing the constraints, [0] lower limit, [1] upper limit} constraint
   * @param {The value to be compared to the constraints} value
   * @returns {True if value is within constraints}
   */
  const insideConstraints = (constraint, value) => {
    return value > constraint[0] && value < constraint[1];
  };

  const darkTheme = {
    width: window.innerWidth * 0.45,
    height: window.innerHeight * 0.5,
    font: {
      family: "Arial, sans-serif",
      size: 14,
      color: "#ffffff",
    },
    paper_bgcolor: "rgba(0,0,0,0)",
    plot_bgcolor: "#2c2c2c",
    margin: {
      l: 40, // left margin
      r: 40, // right margin
      t: 40, // top margin
      b: 40, // bottom margin
    },
    xaxis: {
      gridcolor: "#444",
      zerolinecolor: "#888",
      color: "#ffffff",
      title: {
        font: {
          size: 6,
          color: "#ffffff",
        },
      },
    },
    yaxis: {
      gridcolor: "#444",
      zerolinecolor: "#888",
      color: "#ffffff",
      title: {
        font: {
          size: 6,
          color: "#ffffff",
        },
      },
    },
    title: {
      font: {
        size: 18,
        color: "#ffffff",
      },
    },
    legend: {
      font: {
        size: 12,
        color: "#ffffff",
      },
    },
  };

  return (
    <Plot
      data={filter.data}
      layout={{ ...filter.layout, ...darkTheme }}
      config={filter.config}
      onInitialized={() => {
        handleFilterChange();
      }}
      onRestyle={handleFilterChange}
    />
  );
};

export default Filter;
