import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHistory } from "../redux/slices/postFilterSlice";
import { getActiveJobs } from "../redux/slices/postFilterSlice";
import PostFilter from "./PostFilter";
import { Row, Col } from "react-bootstrap";
import ActiveJobs from "../components/ActiveJobs";
import HistoryJobs from "../components/HistoryJobs";
import SimpleLoading from "../components/SimpleLoading";

export default function Jobs() {
  const dispatch = useDispatch();
  const mainTab = useSelector((state) => state.appState.tab);
  const { job, loadingJob } = useSelector((state) => state.postFilter);
  useEffect(() => {
    if (mainTab === "jobs") dispatch(getHistory());
    dispatch(getActiveJobs());
  }, [mainTab, dispatch]);

  if (mainTab !== "jobs") return;

  return (
    <>
      {loadingJob ? (
        <SimpleLoading />
      ) : job ? (
        <PostFilter />
      ) : (
        <Row style={{ height: "80vh", alignContent: "center",rowGap:'12px' }}>
          <Col className="h-100 overflow-hidden">
            <ActiveJobs />
          </Col>
          <Col sm={6} className="h-100 overflow-scroll">
            <HistoryJobs />
          </Col>
        </Row>
      )}
    </>
  );
}
