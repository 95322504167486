import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputGroup, Form } from "react-bootstrap/";
import { setOptimizationFunction } from "../redux/slices/filterSlice";
export default function Optimize() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setOptimizationFunction("relative_em_uo"));
  }, [dispatch]);
  const filter = useSelector((state) => state.filter);
  return (
    <InputGroup data-bs-theme="dark">
      <InputGroup.Text
        style={{
          width: "30%",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        Optimize Function :
      </InputGroup.Text>

      <Form.Select
        value={filter?.optimisation_function ?? ""}
        onChange={(e) => dispatch(setOptimizationFunction(e.target.value))}
      >
        <option value="relative_em_uo">Relative_Em_Uo</option>
        <option value="optimal_em_u">Optimal_Em_U</option>
        <option value="relative_em_uo_ez">Relative_Em_Uo_ez</option>
      </Form.Select>
    </InputGroup>
  );
}
