/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react";
import { VscLoading } from "react-icons/vsc";
import { Col } from "react-bootstrap";

const rotate = keyframes`
 0% {
    transform: rotate(0deg); /* Starting position */
  }
  100% {
    transform: rotate(360deg); /* Full 360-degree rotation */
  }
`;

const rotatingStyle = css`
  animation: ${rotate} 3s linear infinite;
`;

function SimpleLoading() {
  return (
    <Col sm={12} className="p-1" style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
      <VscLoading
        css={rotatingStyle}
        style={{
          width: "5rem",
          fill: "white",
          height: "5rem",
          margin: "auto",
          filter: "drop-shadow(5px 5px 22px #f3f58e)",
        }}
      />
    </Col>
  );
}

export default SimpleLoading;
